(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('CalendarControllerBis', CalendarControllerBis);

    CalendarControllerBis.$inject = ['$rootScope', '$state', '$timeout', 'Auth','$scope', 'Principal', 'LoginService','RedmineService','pagingParams','paginationConstants','ParseLinks'];

    function CalendarControllerBis ($rootScope,$state, $timeout, Auth,$scope, Principal, LoginService,RedmineService,pagingParams, paginationConstants,ParseLinks) {
        var vm = this;

//        $(document).on("dragover", function(event) {
//            event.preventDefault(); // Empêche le comportement par défaut du navigateur
//            console.log("Événement dragover détecté !");
//        });
//        $(document).on("drag", function(event) {
//            event.preventDefault(); // Empêche le comportement par défaut du navigateur
//            console.log("Événement dragover détecté !");
//        });
//        $(document).on("drop", function(event) {
//            event.preventDefault(); // Empêche le navigateur d'interrompre le drop
//            console.log("Événement drop détecté globalement !");
//        });
//        $(document).on("dropover", function(event) {
//            event.preventDefault(); // Empêche le navigateur d'interrompre le drop
//            console.log("Événement drop détecté globalement !");
//        });

        vm.days = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
        vm.users = ['Utilisateur 1', 'Utilisateur 2', 'Utilisateur 3'];
        
        vm.tasks = [
            { label: 'Tickets A', duration: 3 },
            { label: 'Tickets B', duration: 5 },
            { label: 'Tickets C', duration: 2 }
        ];
        
        vm.planning = {};
        vm.users.forEach(function(user) {
        	vm.planning[user] = {};
        	vm.days.forEach(function(day) {
        		vm.planning[user][day] = [];
            });
        });
    	vm.planning['Utilisateur 1']['Mardi']=    [{ label: 'Tâche A', duration: 3 }];
    	
        vm.onSegmentDragStart  = function (event, ui,task) {
        	vm.currentTask = task;
        };

        vm.onDrop = function (data, event,  user, day) {
        //    console.log("onDrop appelé avec :", data, event,user, day);

            if (!vm.currentTask || typeof vm.currentTask !== 'object') {
                console.error("Erreur : Données incorrectes :", data);
                return;
            }

            
            if (!vm.planning[user][day]) {
                console.warn("La case de réception n'est pas initialisée !");
                vm.planning[user][day] = []; // On initialise la liste si elle n'existe pas
            }
            console.log("Tâche déposée :", data, "Utilisateur :", user, "Jour :", day);
           vm.planning[user][day].push(angular.copy(vm.currentTask));
           
           $(event.draggable).fadeOut(100, function () {
        	 
           });
//            vm.$apply(function () {
//            	vm.planning[user][day].push(data);
//            });
        };
        
        vm.getTotal = function (user, day) {
            return vm.planning[user][day].reduce(function(sum, task) { 
                return sum + task.duration; 
            }, 0);
        };
        
        

        function initCalendar() {
            $('#calendar').fullCalendar({
                defaultView: 'agendaWeek',
                editable: true,
                droppable: true,
                allDaySlot: false,
                slotDuration: '00:30:00',
                minTime: '08:00:00',
                maxTime: '18:00:00',
                columnFormat: 'dddd',
                header: {
                    left: '',
                    center: 'title',
                    right: ''
                },
                resources:  vm.users.map(function(user, index){
                		return {
                    id: index,
                    title: user
                }}),
                eventReceive: function (event) {
                    var dayEvents = $('#calendar').fullCalendar('clientEvents', function (ev) {
                        return ev.resourceId === event.resourceId && ev.start.isSame(event.start, 'day');
                    });

                    var totalDuration = dayEvents.reduce(function(sum, ev) {
                		return sum + ev.duration, 0
                		}
                    );

                    if (totalDuration > 8) {
                        alert("⚠️ Dépassement des 8 heures pour " + event.title + " !");
                        $('#calendar').fullCalendar('removeEvents', event._id);
                    }
                }
            });

            $('.task').each(function () {
                $(this).data('event', {
                    title: $(this).text(),
                    duration: parseInt($(this).attr('data-duration')),
                    stick: true
                }).draggable({
                    zIndex: 999,
                    revert: true,
                    revertDuration: 0,
                    helper: "clone"
                });
            });
        }
         
        $timeout(initCalendar, 0);

            
            
        
        vm.user = {} ;
        Principal.identity().then(function(account) {
        	if(account){
              vm.user =account ;
        	}
        });
        
        vm.transition = transition;
        vm.loadAll = loadAll;

        

        vm.loadAll();
        
        function loadAll() {
        	vm.issuefilter = pagingParams.issuefilter;
        	if(pagingParams.issuefilter == 'myissues'){
        		vm.indextab = 0;
        		 RedmineService.myIssues({
 	                page: pagingParams.page - 1,
 	                size: paginationConstants.itemsPerPage}, onSuccess, onError);
        	}else  if(pagingParams.issuefilter == 'createdbyme'){
        		vm.indextab = 2;
	            RedmineService.issuesCreatedByMe({
	                page: pagingParams.page - 1,
	                size: paginationConstants.itemsPerPage}, onSuccess, onError);	
        	}else  if(pagingParams.issuefilter == 'notassigned'){
        		vm.indextab = 1;
                RedmineService.issuesNotAssigned({
	                page: pagingParams.page - 1,
	                size: paginationConstants.itemsPerPage}, onSuccess, onError);
        	}
        }
        
        
        vm.issuesCreatedByMe = function () {
        	vm.issuefilter = 'createdbyme';
        	loadPage(1);
        }

        vm.myIssues = function () {
        	vm.issuefilter = 'myissues';
        	loadPage(1);
        }

        vm.issuesNotAssigned = function () {
        	vm.issuefilter = 'notassigned';
        	loadPage(1);
        }
        
        function onSuccess(data, headers) {
//            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = data.total_count;
            vm.queryCount = vm.totalItems;
            vm.listIssues = data;
//            vm.page = pagingParams.offset;
          vm.page = (data.offset/data.limit)+1;
            
        }
        
        function onError(error) {
            AlertService.error(error.data.message);
        }
        
        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }
        
        function transition () {
	        $state.transitionTo($state.$current, {
	            page: vm.page,
	            issuefilter: vm.issuefilter
	        	});
        }
        
        
        
    }
    
})();
