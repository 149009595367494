(function() {
    'use strict';
    angular
        .module('wamasysApp')
        .factory('CalendarService', CalendarService);

    CalendarService.$inject = ['$resource','DateUtils'];

    function CalendarService ($resource,DateUtils) {
        var resourceUrl =  'api/calendar/';

        return $resource(resourceUrl, {}, {
             'saveEvent': {
                 method: 'POST',
                 url :'api/calendar/event'
              },
              
             'deleteEvent': {
                 method: 'DELETE',
                 url :'api/calendar/event/:id'
              },
              
             'findEvents': {
                 method: 'GET',
                 url :'api/calendar/find',
                 isArray: true,
                 params: { usersId: '@usersId', from: '@from', to: '@to' }
//              , 
//                 transformResponse: function (data) {
//                     data = angular.fromJson(data);
//
//                     // Si data est un tableau, on transforme chaque objet
//                     if (Array.isArray(data)) {
//                         return data.map(event => {
//                             event.start = DateUtils.convertLocalDateFromServer(event.start);
//                             event.end = DateUtils.convertLocalDateFromServer(event.end);
//                             return event;
//                         });
//                     }
//
//                     // Sinon, transformation d'un seul objet
//                     data.start = DateUtils.convertLocalDateFromServer(data.start);
//                     data.end = DateUtils.convertLocalDateFromServer(data.end);
//                     return data;
//                 }
              }
            		
        });
    }
})();
