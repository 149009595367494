(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('TeamworkController', TeamworkController);

    TeamworkController.$inject = ['$rootScope', '$state', '$timeout', 'Auth','$scope', 'Principal', 'LoginService','RedmineService','pagingParams','paginationConstants','ParseLinks'];

    function TeamworkController ($rootScope,$state, $timeout, Auth,$scope, Principal, LoginService,RedmineService,pagingParams, paginationConstants,ParseLinks) {
        var vm = this;

        
        vm.user = {} ;
        Principal.identity().then(function(account) {
        	if(account){
              vm.user =account ;
        	}
        });
        
        vm.transition = transition;
        vm.loadAll = loadAll;

        

        vm.loadAll();
        
        function loadAll() {
        	vm.issuefilter = pagingParams.issuefilter;
        	if(pagingParams.issuefilter == 'myissues'){
        		vm.indextab = 0;
        		 RedmineService.myIssues({
 	                page: pagingParams.page - 1,
 	                size: paginationConstants.itemsPerPage}, onSuccess, onError);
        	}else  if(pagingParams.issuefilter == 'createdbyme'){
        		vm.indextab = 2;
	            RedmineService.issuesCreatedByMe({
	                page: pagingParams.page - 1,
	                size: paginationConstants.itemsPerPage}, onSuccess, onError);	
        	}else  if(pagingParams.issuefilter == 'notassigned'){
        		vm.indextab = 1;
                RedmineService.issuesNotAssigned({
	                page: pagingParams.page - 1,
	                size: paginationConstants.itemsPerPage}, onSuccess, onError);
        	}
        }
        
        
        vm.issuesCreatedByMe = function () {
        	vm.issuefilter = 'createdbyme';
        	loadPage(1);
        }

        vm.myIssues = function () {
        	vm.issuefilter = 'myissues';
        	loadPage(1);
        }

        vm.issuesNotAssigned = function () {
        	vm.issuefilter = 'notassigned';
        	loadPage(1);
        }
        
        function onSuccess(data, headers) {
//            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = data.total_count;
            vm.queryCount = vm.totalItems;
            vm.listIssues = data;
//            vm.page = pagingParams.offset;
          vm.page = (data.offset/data.limit)+1;
            
        }
        
        function onError(error) {
            AlertService.error(error.data.message);
        }
        
        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }
        
        function transition () {
	        $state.transitionTo($state.$current, {
	            page: vm.page,
	            issuefilter: vm.issuefilter
	        	});
        }
        
        
        
    }
    
})();
